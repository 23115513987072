// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";

const Square = (props) => {
  return (
    <button className="square" onClick={props.onClickEvent}>
      {props.value}
    </button>
  );
};

const Board = () => {
  const initialSquares = Array(9).fill(null);
  const [squares, setSquares] = useState(initialSquares);
  const [xIsNext, setXIsNext] = useState(true);

  const handleClickEvent = (i) => {
    const newSquares = [...squares];

    const winnerDeclared = Boolean(calculateWinner(newSquares));
    const squareFilled = Boolean(newSquares[i]);

    if (winnerDeclared || squareFilled) {
      return;
    }

    newSquares[i] = xIsNext ? "X" : "O";
    setSquares(newSquares);
    setXIsNext(!xIsNext);
  };
  const winner = calculateWinner(squares);
  const status = winner
    ? `We have a Winner! : ${winner}`
    : `Next player turn: ${xIsNext ? "X" : "O"}`;

  const renderSquare = (i) => {
    return (
      <Square value={squares[i]} onClickEvent={() => handleClickEvent(i)} />
    );
  };

  // -----------------------------------------------------
  const handleRestart = () => {
    // winner(null);
    setSquares(Array(9).fill(""));
  };

  return (
    // <div style={{ backgroundColor: "#005D87", margin: 10, padding: 20 }}>
    <div>
      <div className="status">{status}</div>

      <div className="board-row">
        {renderSquare(0)}
        {renderSquare(1)}
        {renderSquare(2)}
      </div>
      <div className="board-row">
        {renderSquare(3)}
        {renderSquare(4)}
        {renderSquare(5)}
      </div>
      <div className="board-row">
        {renderSquare(6)}
        {renderSquare(7)}
        {renderSquare(8)}
      </div>
      {winner && (
        <>
          {/* <button onClick={() => handleRestart()}>Play Again</button> */}
          <div class="container">
            <div class="center">
              <button class="btn" onClick={() => handleRestart()}>
                <svg
                  width="180px"
                  height="60px"
                  viewBox="0 0 180 60"
                  class="border"
                >
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    class="bg-line"
                  />
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    class="hl-line"
                  />
                </svg>
                <span>PLAY AGAIN</span>
              </button>
            </div>
          </div>
          <p style={{ textAlign: "center", marginTop: "40px" }}>GAME OVER</p>
        </>
      )}
    </div>
  );
};

const Game = () => {
  return (
    <div className="game">
      <h1>TIC-TAC-TOE</h1>
      <Board />
    </div>
  );
};

ReactDOM.render(<Game />, document.getElementById("root"));

// ------------------------------------------------------------------------------

function calculateWinner(squares) {
  const lines = [
    //plain rows
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],

    // Winning column combinations
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],

    // Winning diagonal combinations
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let line of lines) {
    const [a, b, c] = line;
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a]; // winner X or O
    }
  }

  return null;
}
